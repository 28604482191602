// export const BASE_URL = "https://api.alrowadinstitute.edunixsystem.com"
// https://edunix-api.beetronix.com
// https://api.alawal.edunixsystem.com
export const BASE_URL = 'https://api.gelalshahbaa.edunixsystem.com/'

// api.gelalshahbaa.edunixsystem.com/files/logo.png

// api.daralfikr.edunixsystem.com/files/logo.png
// https://api.mirror.edunixsystem.com/
export const STORAGE_NAME = "auth"
export const STORAGE_ACTIVE_USER = "active-user"